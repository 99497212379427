/* Header.css */

/* Default light mode styles */
nav {
    position: sticky;
    top: 0; 
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000;
    padding: 10px 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.069);
    font-family: 'Arial', sans-serif;
    z-index: 999;
}

.menu-items {
    display: flex;
    justify-content: center;
}

.menu-items a {
    color: #fff;
    margin: 0 10px;
    text-decoration: none;
    padding: 8px 16px;
    border-radius: 4px;
    transition: background-color 0.3s;
    overflow: hidden;
    max-height: .5;
    transition: max-height 0.3s ease-out;
}

.menu-items a:hover {
    background-color: #e6e6e6;
    color: #000;
}

.menu-items a.active {
    background-color: #007bff;
    color: #fff;
}

/* Hamburger styles */
.hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
    width: 24px;
}

.hamburger div {
    height: 3px;
    background-color: #fff;
    margin: 2px 0;
    transition: 0.4s;
}

/* Mobile view styles */
@media only screen and (max-width: 768px) {
    .menu-items {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s ease;
    }

    .hamburger {
        display: flex;
    }

    nav.open .menu-items {
        display: flex;
        max-height: 500px;
    }
}