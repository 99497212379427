.footer {
    background-color: #000;
    color: #fff;
    text-align: center;
    padding: 20px 0;
    font-family: 'Arial', sans-serif;
}

.social-links-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.social-links-1,
.social-links-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 20px;
}

.social-link {
    color: #fff;
    text-decoration: none;
    margin: 5px 0;
    transition: color 0.3s;
}

.social-link:hover {
    color: #ffdd40;
}

@media (max-width: 768px) {
    .social-links-container {
        flex-direction: column;
    }

    .social-links-1,
    .social-links-2 {
        margin-bottom: 20px;
    }
}
