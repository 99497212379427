* {
    padding: 0;
    box-sizing: border-box;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    display: flex; /* Using flexbox */
    flex-direction: column; /* Stack children vertically */
    justify-content: center; /* Center content vertically */
    min-height: calc(100vh - 60px); /* Adjust based on the footer's height */
}

h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #333;
    font-weight: bold;
}

p {
    font-size: 1.2em;
    color: #555;
    line-height: 1.6;
}

section {
    margin-top: 50px;
}

h2 {
    font-size: 2em;
    margin-bottom: 20px;
    color: #333;
    font-weight: bold;
}

iframe {
    max-width: 100%;
    border: none;
}

@keyframes rotateAnimation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .image-section-spin img {
    animation: rotateAnimation 10s linear infinite;
  }
  

.image-section {
    flex-grow: 1; /* Allows this section to grow and fill available space */
    display: flex;
    justify-content: center;
    align-items: center; /* Center the image vertically and horizontally */
    padding-top: 0%;
}

.box {
    border: 1px solid black; /* adds a border */
    padding: 20px; /* adds space inside the box, around the content */
    margin: 20px; /* adds space outside the box */
    width: 200px; /* sets the width of the box */
    height: 200px; /* sets the height of the box */
    background-color: lightgrey; /* sets the background color of the box */
}


.image-section-home img {
    max-height: 60vh; /* You can adjust this value */
    width: auto; /* Maintain aspect ratio */
    border-radius: 10px; /* Optional: for rounded corners */
}

/* .home-background-image {
    background-image: linear-gradient(to right, black , gray); 
    background-image: url('./dad7.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 100vh;
    width: 100vw; 
} */

.home-background-image {
    background: linear-gradient(to right, black, gray), url('./dad7.jpg');
    background-image: url('./dad7.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    min-height: calc(100vh - 60px);
    width: 100%;
    padding-top: 60px; 
}

/* Larger screens */
@media (min-width: 1024px) {
    .home-background-image {
        background-size: cover;
        background-position: top center;
    }
}

/* Smaller screens */
/* @media (max-width: 600px) {
    .home-background-image {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-attachment: fixed;
        height: 100vh;
        width: 100vw; 
    }
    .image-section {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
    }
} */

/* Smaller screens */
@media (max-width: 600px) {
    .home-background-image {
        background-attachment: scroll;
        min-height: 100vh;
        width: 100%;
    }

    .image-section {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
    }
}
