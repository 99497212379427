/* Login.module.css */

/* Center the login form vertically and horizontally */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  background-color: #f4f4f4;
}

/* Container for the login form */
.container {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  margin: auto;
}

/* Header styles */
h1 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

/* Form styles */
form {
  display: grid;
  gap: 10px;
}

/* Form group styles */
.form-group {
  display: flex;
  flex-direction: column;
}

/* Input field styles */
input[type="email"],
input[type="password"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

label {
  font-weight: bold;
  margin-bottom: 5px;
}

/* Button styles */
button[type="submit"], 
button[type="button"] {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  margin-top: 10px;
}

button[type="submit"]:hover,
button[type="button"]:hover {
  background-color: #0056b3;
}

/* Additional styles for reset password form */
.reset-password-form {
  margin-top: 20px;
  text-align: center;
}

.reset-password-form input[type="email"] {
  margin-bottom: 10px;
}

.reset-password-form button {
  width: 100%;
}
