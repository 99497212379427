/* Gallery Background */
.gallery-container {
  background: radial-gradient(circle, rgba(0, 0, 0, 1) 40%, rgba(20, 20, 20, 1) 100%);
  color: #fff;
  padding: 40px 20px;
  text-align: center;
}

/* Header Styling */
.gallery-header h1 {
  font-size: 42px;
  color: #f8f8f8;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 2px;
  text-shadow: 0px 0px 10px rgba(255, 0, 255, 0.8);
  animation: glow 1.5s infinite alternate;
}

/* Neon Glow Animation */
@keyframes glow {
  0% { text-shadow: 0px 0px 10px rgba(255, 0, 255, 0.8); }
  100% { text-shadow: 0px 0px 20px rgba(255, 255, 0, 0.8); }
}

/* Gallery Grid */
.gallery-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 20px;
  padding: 20px;
}

/* Gallery Item */
.gallery-item {
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  backdrop-filter: blur(10px);
  box-shadow: 0px 4px 10px rgba(255, 0, 255, 0.2);
}

.gallery-item:hover {
  transform: scale(1.05);
  box-shadow: 0px 0px 20px rgba(255, 255, 0, 0.7);
}

/* Gallery Image */
.gallery-item img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 10px;
  transition: opacity 0.3s ease-in-out;
}

.gallery-item:hover img {
  opacity: 0.8;
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  animation: fadeIn 0.3s ease-in-out;
}

/* Modal Image */
.modal-image {
  max-width: 80%;
  max-height: 80vh;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(255, 0, 255, 0.5);
}

/* Close Button */
.modal-close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  font-size: 32px;
  color: #fff;
  transition: transform 0.2s ease;
}

.modal-close:hover {
  transform: scale(1.1);
}

/* Cool Image Section */
.image-section-gal {
  text-align: center;
  width: 100%;
  padding-top: 20px;
}

.image-section-gal img {
  display: block;
  margin: 0 auto;
  max-height: 60vh;
  width: auto;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(255, 0, 255, 0.5);
  transition: transform 0.3s ease-in-out;
}

.image-section-gal img:hover {
  transform: scale(1.05);
}
